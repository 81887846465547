import React from "react";

const WorkItems = ({ item }) => {
  return (
    <div className="work__card" key={item.id}>
      <div className="img__container">
        <img src={item.image} alt="" className="work__img" />
      </div>
      <h3 className="work__title">{item.title}</h3>
      {item.subtitle ? <i class="bx bx-info-circle"></i> : null}
      <h2 className="work__subtitle">{item.subtitle ? item.subtitle : null}</h2>
      <div className="link_container">
        <a
          href={item.url ? item.url : "#"}
          target={item.url ? "_blank" : "_self"}
          rel="noreferrer"
          className="work__button"
        >
          Demo
          <i className="bx bx-right-arrow-alt work__button-icon"></i>
        </a>
        <a
          href={item.github ? item.github : "#"}
          className="home__social-icon"
          target={item.github ? "_blank" : "_self"}
          rel="noreferrer"
        >
          <i className="uil uil-github-alt"></i>
        </a>
      </div>
    </div>
  );
};

export default WorkItems;
