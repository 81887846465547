import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";
import Work6 from "../../assets/work6.jpg";
import Work7 from "../../assets/work7.jpg";
import Work8 from "../../assets/work8.jpg";
import Work9 from "../../assets/work9.jpg";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "Natours",
    subtitle: "Sass and Responsive Design",
    category: "Sass",
    url: "https://natours.highcold.io/",
    github: "https://github.com/hgao1604/natours",
  },
  {
    id: 2,
    image: Work2,
    title: "Trillo",
    subtitle: "Flexbox and Responsive Design",
    category: "Sass",
    url: "https://trillo.highcold.io/",
    github: "https://github.com/hgao1604/trillo",
  },
  {
    id: 3,
    image: Work3,
    title: "Nexter",
    subtitle: "Grid and Responsive Design",
    category: "Sass",
    url: "https://nexter.highcold.io/",
    github: "https://github.com/hgao1604/nexter",
  },
  {
    id: 4,
    image: Work4,
    title: "Clipboard",
    subtitle: "Responsive Design",
    category: "TailwindCSS",
    url: "https://clipboard.highcold.io/",
    github: "https://github.com/hgao1604/clipboard",
  },
  {
    id: 5,
    image: Work5,
    title: "Loopstudios",
    subtitle: "Responsive Design",
    category: "TailwindCSS",
    url: "https://loopstudios.highcold.io/",
    github: "https://github.com/hgao1604/loopstudios",
  },
  {
    id: 6,
    image: Work6,
    title: "Shortly",
    subtitle: "Responsive Design",
    category: "TailwindCSS",
    url: "https://shortly.highcold.io/",
    github: "https://github.com/hgao1604/shortly",
  },
  {
    id: 7,
    image: Work7,
    title: "usePopcorn",
    subtitle: "useEffect/ Custom hook/ Refs",
    category: "react",
    url: "https://popcorn.highcold.io/",
    github: "https://github.com/hgao1604/popcorn",
  },
  {
    id: 8,
    image: Work8,
    title: "reactQuiz",
    subtitle: "Redux",
    category: "react",
    url: "https://react-quiz.highcold.io/",
    github: "https://github.com/hgao1604/reactQuiz",
  },
  {
    id: 9,
    image: Work9,
    title: "worldwise",
    subtitle: "React Router",
    category: "react",
    url: "https://worldwise.highcold.io/",
    github: "https://github.com/hgao1604/worldwise",
  },
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "Sass",
  },
  {
    name: "TailwindCSS",
  },
  {
    name: "React",
  },
];
